import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { colorGolden, colorPrimaryBlue } from '../../config/theme';

function roundToNearestLakh(value) {
  return value;
}

const DashboardIncentiveGraph = ({ amount, target, achieved }) => {
  const [maxAmount] = useState(Math.max(...target, ...achieved));

  const labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];

  const maximumAmount = useMemo(() => {
    const principalAmount = Number(maxAmount);
    const annualInterestRate =
      Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
    return principalAmount * Math.pow(1 + annualInterestRate, 1);
  }, [amount]);

  const options = {
    plotOptions: {
      column: {
        pointWidth: 20
      }
    },
    chart: {
      type: 'column'
    },
    title: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: labels,
      title: {
        text: ''
      },
      gridLineWidth: 1
    },
    yAxis: {
      max: roundToNearestLakh(maximumAmount),
      title: {
        text: 'Amount(₹)'
      },
      labels: {
        formatter: function () {
          return `₹ ${new Intl.NumberFormat('en-IN').format(this.value)}`;
        }
      }
    },
    tooltip: {
      borderRadius: 10,
      formatter: function () {
        return `<span style="font-weight: bold;">Amount</span><br/><span style="font-size: larger;font-weight: bold;">₹ ${new Intl.NumberFormat(
          'en-IN'
        ).format(this.y)}</span>`;
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        showInLegend: false,
        name: 'Target',
        data: target,
        color: colorGolden
      },
      {
        showInLegend: false,
        name: 'Achieved',
        data: achieved.map(value => ({
          y: value,
          marker: { symbol: 'circle', fillColor: colorPrimaryBlue }
        })),
        type: 'line',
        color: colorPrimaryBlue
      }
    ]
  };

  return (
    <>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <HighchartsReact
          allowChartUpdate={true}
          immutable={false}
          updateArgs={[true, true, true]}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </>
  );
};

DashboardIncentiveGraph.propTypes = {
  amount: PropTypes.any,
  achieved: PropTypes.array,
  target: PropTypes.array
};

export default DashboardIncentiveGraph;
