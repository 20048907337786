import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Slide,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearLayoutState,
  profilePicDelete,
  profilePicUpload
} from '../../redux/reducers/layoutSlice';
import { SELECT_IMAGE_MESSAGE, imageAllowedTypes } from '../../utils/constant';
import ImageCropper from '../../components/ImageCropper';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarBox from '../../components/SnackBarBox';
import {
  enterOnlyEmail,
  enterOnlyNumbers,
  handleError,
  isImageExtensionAllowed,
  isValidEmail,
  isValidMobileNumber,
  isValidOtp,
  validateChar,
  generateImageSrc,
  enterOnlyAlphabates,
  isAlphabet
} from '../../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input';
import OtpTimer from '../../components/OtpTimer';
import {
  clearProfileState,
  fetchRelationshipManagerDetails,
  reSendEmailVerificationOtp,
  reSendMobileNoVerificationOtp,
  sendEmailVerificationOtp,
  sendMobileNoVerificationOtp,
  setEmailId,
  setMobileNo,
  updateBranchDetail,
  verifyAndUpdateEmail,
  verifyAndUpdateMobileNo
} from '../../redux/reducers/profileSlice';
import Loader from '../../components/Loader';
import {
  BRANCH_UPDATED_SUCCESSFULLY,
  EMAIL_UPDATED_SUCCESSFULLY,
  MOBILE_NO_UPDATED_SUCCESSFULLY,
  OTP_SENT_TO_NEW_EMAIL_ID,
  OTP_SENT_TO_NEW_MOBILE_NO,
  SENDING_OTP,
  VERIFYING_OTP
} from '../../utils/messages';
import SkeletonBars from '../../components/SkeletonBars';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const PROFILE_PIC = '/images/person.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserPhoto() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const fileInputRef = useRef(null);
  const { authData } = useSelector(state => state.auth);
  const {
    isUploadProfilePicLoading,
    isUploadProfilePicSuccess,
    isUploadProfilePicFailed,
    uploadProfilePicErrorContainer,
    isFetchProfilePicLoading,
    isDeleteProfilePicLoading,
    isDeleteProfilePicSuccess,
    isDeleteProfilePicFailed,
    DeleteProfilePicErrorContainer
  } = useSelector(state => state.layout);

  const { relationshipManagerDetails } = useSelector(state => state.profile);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [tempUploadImage, setTempUploadImage] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState('');

  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });

  useEffect(() => {
    if (!String(relationshipManagerDetails.profileImage).startsWith('/image')) {
      setUploadedImage(relationshipManagerDetails.profileImage);
    }
  }, [relationshipManagerDetails]);

  useEffect(() => {
    return () => dispatch(clearLayoutState());
  }, []);

  const handleImageUpload = event => {
    handleClose();
    fileInputRef.current.click();
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = imageAllowedTypes;
      const maxSizeMB = 1;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            reader.onload = e => {
              setTempUploadImage(e.target.result);
            };

            reader.readAsDataURL(file);
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: SELECT_IMAGE_MESSAGE
        });
      }
    }
  };

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleDeleteImage();
        handleClose();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  const handleDeleteImage = () => {
    setTempUploadImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 0);
  };

  const handleRemoveImage = () => {
    dispatch(profilePicDelete({ rmId: authData?.userId }));
    handleDeleteImage();
    handleClose();
    setTempUploadImage(null);
    setUploadedImage(null);
  };

  const containerStyle = {
    position: 'relative',
    display: 'inline-block'
  };

  const buttonStyle = {
    position: 'absolute',
    bottom: '-2px',
    right: '-8px',
    backgroundColor: '#303157'
  };

  // profile photo update
  const handleFileUpload = file => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(profilePicUpload({ data: formData, rmId: authData.userId }));
    setTempUploadImage(null);
  };

  return (
    <>
      <div style={containerStyle}>
        {tempUploadImage && (
          <ImageCropper
            key="profilePicture"
            uploadedFileName={uploadedFileName}
            incomingFile={tempUploadImage}
            handleFileUpload={handleFileUpload}
            handleFileCancel={handleDeleteImage}
          />
        )}
        {isFetchProfilePicLoading ||
        isUploadProfilePicLoading ||
        isDeleteProfilePicLoading ? (
          <>
            <Skeleton variant="circular" width={70} height={70} />
          </>
        ) : (
          <img
            style={{
              borderRadius: '10%',
              boxShadow:
                '0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19)'
            }}
            width={100}
            height={100}
            src={uploadedImage ? generateImageSrc(uploadedImage) : PROFILE_PIC}
            alt="User Photo"
          />
        )}
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'editImage' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="secondary"
          aria-label="modifyImage"
          style={buttonStyle}
        >
          <BorderColorOutlinedIcon sx={{ fontSize: '13px' }} />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 24,
                  height: 24,
                  ml: -0.5,
                  mr: 1
                }
              }
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom'
            }}
          >
            <MenuItem className="custom_menu" component="label">
              <UploadIcon sx={{ marginRight: '5px', fontSize: '20px' }} />
              Upload
              <input
                ref={fileInputRef}
                key={'uploadProfile'}
                type="file"
                style={{ display: 'none' }}
                accept={imageAllowedTypes.join(',')}
                onChange={handleImageUpload}
              />
            </MenuItem>
            {uploadedImage && (
              <MenuItem
                sx={{ color: '#F3413B' }}
                className="custom_menu"
                onClick={handleRemoveImage}
              >
                <DeleteIcon sx={{ marginRight: '5px', fontSize: '20px' }} />
                Remove
              </MenuItem>
            )}
          </Menu>
        </IconButton>
      </div>
      {imageUploadError.error && (
        <SnackBarBox
          type="error"
          message={handleError(imageUploadError.errorMessage)}
        />
      )}

      {!isUploadProfilePicLoading && isUploadProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(uploadProfilePicErrorContainer?.errorMessage)}
        />
      )}
      {!isDeleteProfilePicLoading && isDeleteProfilePicFailed && (
        <SnackBarBox
          type="error"
          message={handleError(DeleteProfilePicErrorContainer?.errorMessage)}
        />
      )}
      {!isUploadProfilePicLoading && isUploadProfilePicSuccess && (
        <SnackBarBox
          type="success"
          message="Profile picture successfully uploaded."
        />
      )}
      {!isDeleteProfilePicLoading && isDeleteProfilePicSuccess && (
        <SnackBarBox
          type="success"
          message="Profile picture successfully removed."
        />
      )}
    </>
  );
}

const Modal = ({ title, isOpen, handleClose, children }) => {
  const closeDialog = handleClose => {
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog(event, reason);
        }
      }}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              marginTop={2}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              className="closeButton"
              onClick={() => closeDialog(handleClose)}
            >
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="MuiDialogContentRootc">
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const formControl = {
  margin: '0.5rem auto'
};

function RMProfile() {
  // constant
  const oneMinuteFromNow = 60;
  const dispatch = useDispatch();

  // states
  const [otpExpired, setOTPExpired] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [isEditBranch, setIsEditBranch] = useState(false);
  const [isEditMobileNo, setIsEditMobileNo] = useState(false);
  const [isEmailOtpSent, setIsEmailOtpSent] = useState(false);
  const [isMobileNoOtpSent, setIsMobileNoOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpVerifier, setOtpVerifier] = useState({
    otp: '',
    isOtpError: false,
    errorMessage: ''
  });

  // selectors
  const { relationshipManagerDetails } = useSelector(state => state.profile);
  const { authData } = useSelector(state => state.auth);
  const {
    email,
    mobileNo,
    isSendEmailVerificationOtpLoading,
    isSendEmailVerificationOtpSuccess,
    isSendEmailVerificationOtpFailed,
    sendEmailVerificationErrorContainer,
    isVerifyAndUpdateEmailLoading,
    isVerifyAndUpdateEmailSuccess,
    isVerifyAndUpdateEmailFailed,
    verifyAndUpdateEmailErrorContainer,
    isSendMobileNoVerificationOtpLoading,
    isSendMobileNoVerificationOtpSuccess,
    isSendMobileNoVerificationOtpFailed,
    sendMobileNoVerificationOtpErrorContainer,
    isVerifyAndUpdateMobileNoLoading,
    isVerifyAndUpdateMobileNoSuccess,
    isVerifyAndUpdateMobileNoFailed,
    verifyAndUpdateMobileNoErrorContainer,
    isReSendEmailVerificationOtpLoading,
    isReSendEmailVerificationOtpSuccess,
    isReSendEmailVerificationOtpFailed,
    reSendEmailVerificationErrorContainer,
    isReSendMobileNoVerificationOtpLoading,
    isReSendMobileNoVerificationOtpSuccess,
    isReSendMobileNoVerificationOtpFailed,
    reSendMobileNoVerificationOtpErrorContainer,
    isBranchUpdateLoading,
    isBranchUpdateSuccess,
    isBranchUpdateFailed,
    branchUpdateErrorContainer
  } = useSelector(state => state.profile);
  const { isUploadProfilePicLoading, isUploadProfilePicSuccess } = useSelector(
    state => state.layout
  );

  useEffect(() => {
    async function fetchDetails() {
      setIsLoading(true);
      dispatch(fetchRelationshipManagerDetails(authData.userId));
      setIsLoading(false);
    }

    fetchDetails();
    return () => {
      dispatch(clearProfileState());
      setIsEmailOtpSent(false);
      setIsMobileNoOtpSent(false);
    };
  }, []);

  const handleEditBranch = () => {
    setIsEditBranch(true);
  };

  const handleEditMobile = () => {
    setIsEditMobileNo(true);
    setOtpVerifier({
      otp: '',
      isOtpError: false,
      errorMessage: ''
    });
  };

  const handleEditEmail = () => {
    setIsEditEmail(true);
    setOtpVerifier({
      otp: '',
      isOtpError: false,
      errorMessage: ''
    });
  };

  const handleEditBranchClose = () => {
    setIsEditBranch(false);
    editBranchFormik.setFieldValue(
      'branch',
      relationshipManagerDetails?.branchName
    );
  };

  const handleEditMobileClose = () => {
    setIsEditMobileNo(false);
    setIsMobileNoOtpSent(false);
    handleMobileVerificationClose();
  };

  const handleEditEmailClose = () => {
    setIsEditEmail(false);
    setIsEmailOtpSent(false);
    handleEmailVerificationClose();
  };

  const handleEmailVerificationClose = () => {
    dispatch(setEmailId(authData?.email));
    editEmailFormik.setFieldValue(
      'email',
      authData?.email || relationshipManagerDetails?.rmEmailId
    );
    setIsEditEmail(false);
    setIsEmailOtpSent(false);
  };

  const handleMobileVerificationClose = () => {
    dispatch(setMobileNo(authData?.mobileNo));
    editMobileNoFormik.setFieldValue(
      'mobileNo',
      authData?.mobileNo || relationshipManagerDetails?.rmMobileNo
    );
    setIsEditMobileNo(false);
    setIsMobileNoOtpSent(false);
  };

  const editBranchFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: authData?.userId || relationshipManagerDetails.rmId,
      branch: relationshipManagerDetails.branchName
    },
    validationSchema: yup.object({
      branch: yup
        .string()
        .trim()
        .required('Branch is required')
        .test(
          'is-alphabet',
          'Branch should consist of alphabetic characters only.',
          value => isAlphabet(value)
        )
    }),
    onSubmit: values => {
      const updatedValues = {
        branchName: values.branch.trim(),
        rmId: authData?.userId || relationshipManagerDetails.rmId
      };
      dispatch(updateBranchDetail(updatedValues));
    }
  });

  const editMobileNoFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: authData?.userId || relationshipManagerDetails.rmId,
      mobileNo: authData?.mobileNo || relationshipManagerDetails.rmMobileNo
    },
    validationSchema: yup.object({
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Mobile number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .min(10, 'Mobile Number should be minimum of 10 digit.')
        .required('Mobile Number is required')
    }),
    onSubmit: values => {
      const updatedValues = {
        rmMobileNo: values.mobileNo,
        rmId: authData.userId
      };
      dispatch(sendMobileNoVerificationOtp(updatedValues));
    }
  });

  const editEmailFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: authData?.userId || relationshipManagerDetails.rmId,
      email: authData?.email || relationshipManagerDetails.rmEmailId
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required')
    }),
    onSubmit: values => {
      const updatedValues = {
        rmEmailId: values.email,
        rmId: authData?.userId || relationshipManagerDetails.rmId
      };
      dispatch(sendEmailVerificationOtp(updatedValues));
    }
  });

  useEffect(() => {
    if (!isBranchUpdateLoading && isBranchUpdateSuccess) {
      setIsEditBranch(false);
      dispatch(fetchRelationshipManagerDetails(authData.userId));
    }
  }, [isBranchUpdateSuccess]);

  useEffect(() => {
    if (
      !isSendEmailVerificationOtpLoading &&
      isSendMobileNoVerificationOtpSuccess
    ) {
      setIsEditMobileNo(false);
      setIsMobileNoOtpSent(true);
    }
  }, [isSendMobileNoVerificationOtpSuccess]);

  useEffect(() => {
    if (!isVerifyAndUpdateMobileNoLoading && isVerifyAndUpdateMobileNoSuccess) {
      setIsMobileNoOtpSent(false);
      dispatch(fetchRelationshipManagerDetails(authData.userId));
    }
  }, [isVerifyAndUpdateMobileNoSuccess]);

  useEffect(() => {
    if (
      !isSendEmailVerificationOtpLoading &&
      isSendEmailVerificationOtpSuccess
    ) {
      setIsEditEmail(false);
      setIsEmailOtpSent(true);
    }
  }, [isSendEmailVerificationOtpSuccess]);

  useEffect(() => {
    if (!isVerifyAndUpdateEmailLoading && isVerifyAndUpdateEmailSuccess) {
      setIsEmailOtpSent(false);
      dispatch(fetchRelationshipManagerDetails(authData.userId));
    }
  }, [isVerifyAndUpdateEmailSuccess]);

  useEffect(() => {
    if (!isUploadProfilePicLoading && isUploadProfilePicSuccess) {
      dispatch(fetchRelationshipManagerDetails(authData.userId));
    }
  }, [isUploadProfilePicSuccess]);

  const handleOTPTimerExpired = () => {
    setOTPExpired(true);
  };

  const handleOtpChange = e => {
    setOtpVerifier({
      ...otpVerifier,
      otp: e.trim(),
      isOtpError: false,
      errorMessage: ''
    });
  };

  const handleResendEmailOtp = () => {
    setOtpVerifier(prev => {
      return { ...prev, otp: '' };
    });
    const updatedValues = {
      rmId: authData.userId,
      rmEmailId: editEmailFormik.values.email
        ? editEmailFormik.values.email
        : email
    };
    dispatch(reSendEmailVerificationOtp(updatedValues));
    setOTPExpired(false);
  };

  const handleResendMobileOtp = () => {
    setOtpVerifier(prev => {
      return { ...prev, otp: '' };
    });
    const updatedValues = {
      rmId: authData.userId,
      rmMobileNo: editMobileNoFormik.values.mobileNo
        ? editMobileNoFormik.values.mobileNo
        : mobileNo
    };
    dispatch(reSendMobileNoVerificationOtp(updatedValues));
    setOTPExpired(false);
  };

  const handleVerifyEmailOtp = () => {
    const validOtp = isValidOtp(otpVerifier.otp);
    if (validOtp) {
      setOtpVerifier({
        ...otpVerifier,
        isOtpError: false,
        errorMessage: ''
      });
      dispatch(
        verifyAndUpdateEmail({
          rmId: authData.userId,
          emailOTP: otpVerifier.otp,
          rmEmailId: editEmailFormik.values.email
            ? editEmailFormik.values.email
            : email
        })
      );
    } else {
      if (otpVerifier.otp.length !== 0 && otpVerifier.otp.length < 4) {
        setOtpVerifier({
          ...otpVerifier,
          isOtpError: true,
          errorMessage: 'OTP must be of 4 digits.'
        });
      } else {
        setOtpVerifier({
          ...otpVerifier,
          isOtpError: true,
          errorMessage: 'OTP is required.'
        });
      }
    }
  };

  const handleVerifyMobileNoOtp = () => {
    const validOtp = isValidOtp(otpVerifier.otp);
    if (validOtp) {
      setOtpVerifier({
        ...otpVerifier,
        isOtpError: false,
        errorMessage: ''
      });
      dispatch(
        verifyAndUpdateMobileNo({
          rmId: authData.userId,
          smsOTP: otpVerifier.otp,
          rmMobileNo: editMobileNoFormik.values.mobileNo
            ? editMobileNoFormik.values.mobileNo
            : mobileNo
        })
      );
    } else {
      if (otpVerifier.otp.length !== 0 && otpVerifier.otp.length < 4) {
        setOtpVerifier({
          ...otpVerifier,
          isOtpError: true,
          errorMessage: 'OTP must be of 4 digits.'
        });
      } else {
        setOtpVerifier({
          ...otpVerifier,
          isOtpError: true,
          errorMessage: 'OTP is required.'
        });
      }
    }
  };

  const handleOnlyAlphabtes = (e, field) => {
    editBranchFormik.setFieldValue(field, enterOnlyAlphabates(e.target.value));
  };

  return (
    <>
      {isLoading ? (
        <SkeletonBars />
      ) : (
        <Grid container marginTop={2}>
          <Grid item xs={12}>
            <UserPhoto />
          </Grid>

          <Grid item xs={12} marginTop={3}>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                type="text"
                size="small"
                label="RM Name"
                value={
                  relationshipManagerDetails.fullName
                    ? relationshipManagerDetails.fullName
                    : ''
                }
                readOnly
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                type="text"
                size="small"
                label="RM ID"
                value={relationshipManagerDetails?.relationshipManagerId || ''}
                readOnly
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                type="text"
                size="small"
                label="Branch Name"
                value={relationshipManagerDetails?.branchName || ''}
                readOnly
                disabled
                onChange={e => handleOnlyAlphabtes(e, 'branch')}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      type="button"
                      position="end"
                      onClick={handleEditBranch}
                    >
                      <span
                        style={{
                          padding: '0.5rem',
                          cursor: 'pointer',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <BorderColorIcon color="golden" />
                      </span>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                label="Mobile Number"
                value={
                  relationshipManagerDetails?.rmMobileNo
                    ? relationshipManagerDetails?.rmMobileNo
                    : authData.mobileNo
                }
                type="tel"
                readOnly
                disabled
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      type="button"
                      position="end"
                      onClick={handleEditMobile}
                    >
                      <span
                        style={{
                          padding: '0.5rem',
                          cursor: 'pointer',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <BorderColorIcon color="golden" />
                      </span>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                size="small"
                label="Email ID"
                type="email"
                value={
                  relationshipManagerDetails?.rmEmailId
                    ? relationshipManagerDetails?.rmEmailId
                    : authData?.email
                }
                readOnly
                disabled
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      type="button"
                      position="end"
                      onClick={handleEditEmail}
                    >
                      <span
                        style={{
                          padding: '0.5rem',
                          cursor: 'pointer',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <BorderColorIcon color="golden" />
                      </span>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Modal
        title="Edit"
        isOpen={isEditBranch}
        handleClose={handleEditBranchClose}
      >
        <form autoComplete="off" onSubmit={editBranchFormik.handleSubmit}>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <FormControl sx={formControl} fullWidth size="small">
                <TextField
                  size="small"
                  required
                  label="Enter Branch"
                  inputProps={{ maxLength: 60 }}
                  name="branch"
                  value={editBranchFormik.values.branch}
                  onChange={e => handleOnlyAlphabtes(e, 'branch')}
                  error={Boolean(
                    editBranchFormik.touched.branch &&
                      editBranchFormik.errors.branch
                  )}
                  helperText={
                    editBranchFormik.touched.branch &&
                    editBranchFormik.errors.branch
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Modal>

      <Modal
        title="Edit"
        isOpen={isEditEmail}
        handleClose={handleEditEmailClose}
      >
        <form autoComplete="off" onSubmit={editEmailFormik.handleSubmit}>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <FormControl sx={formControl} fullWidth size="small">
                <TextField
                  size="small"
                  type="email"
                  required
                  label="Enter Email ID"
                  inputProps={{ maxLength: 60 }}
                  name="email"
                  value={editEmailFormik.values.email}
                  onChange={e =>
                    editEmailFormik.setFieldValue(
                      'email',
                      enterOnlyEmail(e.target.value)
                    )
                  }
                  error={Boolean(
                    editEmailFormik.touched.email &&
                      editEmailFormik.errors.email
                  )}
                  helperText={
                    editEmailFormik.touched.email &&
                    editEmailFormik.errors.email
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Verify
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Modal>

      <Modal
        title="Edit"
        isOpen={isEditMobileNo}
        handleClose={handleEditMobileClose}
      >
        <form autoComplete="off" onSubmit={editMobileNoFormik.handleSubmit}>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <FormControl sx={formControl} fullWidth size="small">
                <TextField
                  size="small"
                  type="text"
                  required
                  label="Enter Mobile No"
                  inputProps={{ maxLength: 10 }}
                  name="mobileNo"
                  value={editMobileNoFormik.values.mobileNo}
                  onChange={e =>
                    editMobileNoFormik.setFieldValue(
                      'mobileNo',
                      enterOnlyNumbers(e.target.value)
                    )
                  }
                  error={Boolean(
                    editMobileNoFormik.touched.mobileNo &&
                      editMobileNoFormik.errors.mobileNo
                  )}
                  helperText={
                    editMobileNoFormik.touched.mobileNo &&
                    editMobileNoFormik.errors.mobileNo
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                style={{ textTransform: 'uppercase' }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Verify
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Modal>

      <Modal
        title="OTP Verification"
        isOpen={isEmailOtpSent}
        handleClose={handleEmailVerificationClose}
      >
        <Typography align="center" variant="subtitle2" marginTop={2}>
          Enter OTP sent via Email on - {editEmailFormik.values.email}
        </Typography>

        <FormControl
          variant="outlined"
          fullWidth
          size="small"
          sx={{ marginTop: '1.5rem' }}
        >
          <MuiOtpInput
            className="otp_input_custom"
            length={4}
            value={otpVerifier.otp}
            onChange={handleOtpChange}
            validateChar={validateChar}
            TextFieldsProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              type: 'tel'
            }}
          />
          {otpVerifier.isOtpError && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {otpVerifier.errorMessage}
            </FormHelperText>
          )}
        </FormControl>

        {!otpExpired && (
          <OtpTimer
            expiryTimestamp={oneMinuteFromNow}
            onTimeout={handleOTPTimerExpired}
          />
        )}

        <Typography
          sx={formControl}
          align="center"
          variant="subtitle2"
          fontSize={'0.8rem'}
        >
          Didn&apos;t receive a OTP?
        </Typography>

        <Typography sx={formControl} align="center" variant="body2">
          <Button
            size="small"
            variant="default"
            onClick={handleResendEmailOtp}
            disabled={!otpExpired}
          >
            RESEND OTP
          </Button>
        </Typography>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginTop={2}
        >
          <Grid item xs={3}>
            <LoadingButton
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleVerifyEmailOtp}
              disabled={isVerifyAndUpdateEmailLoading}
              loading={isVerifyAndUpdateEmailLoading}
            >
              {isVerifyAndUpdateEmailLoading ? 'Verifying' : 'Verify & Proceed'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title="OTP Verification"
        isOpen={isMobileNoOtpSent}
        handleClose={handleMobileVerificationClose}
      >
        <Typography align="center" variant="subtitle2" marginTop={2}>
          Enter OTP sent via SMS on - {editMobileNoFormik.values.mobileNo}
        </Typography>

        <FormControl
          variant="outlined"
          fullWidth
          size="small"
          sx={{ marginTop: '1.5rem' }}
        >
          <MuiOtpInput
            className="otp_input_custom"
            length={4}
            value={otpVerifier.otp}
            onChange={handleOtpChange}
            validateChar={validateChar}
            TextFieldsProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              type: 'tel'
            }}
          />
          {otpVerifier.isOtpError && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {otpVerifier.errorMessage}
            </FormHelperText>
          )}
        </FormControl>

        {!otpExpired && (
          <OtpTimer
            expiryTimestamp={oneMinuteFromNow}
            onTimeout={handleOTPTimerExpired}
          />
        )}

        <Typography
          sx={formControl}
          align="center"
          variant="subtitle2"
          fontSize={'0.8rem'}
        >
          Didn&apos;t receive a OTP?
        </Typography>

        <Typography sx={formControl} align="center" variant="body2">
          <Button
            size="small"
            variant="default"
            onClick={handleResendMobileOtp}
            disabled={!otpExpired}
          >
            RESEND OTP
          </Button>
        </Typography>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginTop={2}
        >
          <Grid item xs={3}>
            <LoadingButton
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleVerifyMobileNoOtp}
              disabled={isVerifyAndUpdateMobileNoLoading}
              loading={isVerifyAndUpdateMobileNoLoading}
            >
              {isVerifyAndUpdateMobileNoLoading
                ? 'Verifying'
                : 'Verify & Proceed'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>

      {isSendEmailVerificationOtpLoading && (
        <Loader key="loadingSendEmailOtp" text={SENDING_OTP} />
      )}
      {isSendMobileNoVerificationOtpLoading && (
        <Loader key="loadingSendMobileNoOtp" text={SENDING_OTP} />
      )}
      {isVerifyAndUpdateEmailLoading && (
        <Loader key="verifyingEmailOtp" text={VERIFYING_OTP} />
      )}
      {isVerifyAndUpdateMobileNoLoading && (
        <Loader key="verifyingMobileNOOtp" text={VERIFYING_OTP} />
      )}

      {!isSendEmailVerificationOtpLoading &&
        isSendEmailVerificationOtpSuccess && (
          <SnackBarBox type="success" message={OTP_SENT_TO_NEW_EMAIL_ID} />
        )}

      {!isReSendEmailVerificationOtpLoading &&
        isReSendEmailVerificationOtpSuccess && (
          <SnackBarBox type="success" message={OTP_SENT_TO_NEW_EMAIL_ID} />
        )}

      {!isSendEmailVerificationOtpLoading &&
        isSendEmailVerificationOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              sendEmailVerificationErrorContainer?.errorMessage
            )}
          />
        )}
      {!isReSendEmailVerificationOtpLoading &&
        isReSendEmailVerificationOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              reSendEmailVerificationErrorContainer?.errorMessage
            )}
          />
        )}

      {!isReSendMobileNoVerificationOtpLoading &&
        isReSendMobileNoVerificationOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              reSendMobileNoVerificationOtpErrorContainer?.errorMessage
            )}
          />
        )}

      {!isVerifyAndUpdateEmailLoading && isVerifyAndUpdateEmailSuccess && (
        <SnackBarBox type="success" message={EMAIL_UPDATED_SUCCESSFULLY} />
      )}

      {!isVerifyAndUpdateEmailLoading && isVerifyAndUpdateEmailFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            verifyAndUpdateEmailErrorContainer?.errorMessage
          )}
        />
      )}

      {!isSendMobileNoVerificationOtpLoading &&
        isSendMobileNoVerificationOtpSuccess && (
          <SnackBarBox type="success" message={OTP_SENT_TO_NEW_MOBILE_NO} />
        )}

      {!isReSendMobileNoVerificationOtpLoading &&
        isReSendMobileNoVerificationOtpSuccess && (
          <SnackBarBox type="success" message={OTP_SENT_TO_NEW_MOBILE_NO} />
        )}

      {!isSendMobileNoVerificationOtpLoading &&
        isSendMobileNoVerificationOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              sendMobileNoVerificationOtpErrorContainer?.errorMessage
            )}
          />
        )}
      {!isVerifyAndUpdateMobileNoLoading &&
        isVerifyAndUpdateMobileNoSuccess && (
          <SnackBarBox
            type="success"
            message={MOBILE_NO_UPDATED_SUCCESSFULLY}
          />
        )}

      {!isVerifyAndUpdateMobileNoLoading && isVerifyAndUpdateMobileNoFailed && (
        <SnackBarBox
          type="error"
          message={handleError(
            verifyAndUpdateMobileNoErrorContainer?.errorMessage
          )}
        />
      )}

      {!isBranchUpdateLoading && isBranchUpdateSuccess && (
        <SnackBarBox type="success" message={BRANCH_UPDATED_SUCCESSFULLY} />
      )}

      {!isBranchUpdateLoading && isBranchUpdateFailed && (
        <SnackBarBox
          type="error"
          message={handleError(branchUpdateErrorContainer?.errorMessage)}
        />
      )}
    </>
  );
}

export default RMProfile;
