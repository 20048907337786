import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';
import { TEMPLATE_USER_MOBILE_OTP } from '../../utils/notificationTemplateCodes';

export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

export const clearOtpVerificationState = createAction(
  'CLEAR_OTP_VERIFICATION_STATE'
);

export const clearLeadManagementState = createAction(
  'CLEAR_LEAD_MANAGEMENT_STATE'
);

export const getLeadByRM = createAsyncThunk(
  'users/getLeads',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post('users/getLeadByRM', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProspectByRM = createAsyncThunk(
  'users/getProspectStatusByRM',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        'users/getProspectStatusByRM',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOtpForLeadCreation = createAsyncThunk(
  'notifications/lead_create_send_api',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `notifications/resendOTP?userId=${userId}&templateCode=${TEMPLATE_USER_MOBILE_OTP}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reSendOtpForLeadCreation = createAsyncThunk(
  'notifications/lead_create_resend_api',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `notifications/resendOTP?userId=${userId}&templateCode=${TEMPLATE_USER_MOBILE_OTP}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeadByRMBorrower = createAsyncThunk(
  'users/getLeadByRmForBorrower',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        'users/getLeadByRmForBorrower' /* Need to replace api path*/,
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProspectByRMBorrower = createAsyncThunk(
  'users/getProspectByRmForBorrower',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        'users/getProspectByRmForBorrower' /* Need to replace api path*/,
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  isLoadingLeads: false,
  isLoadLeadsSuccess: false,
  isLoadLeadsFailed: false,
  leadsContainer: null,
  isLoadingProspect: false,
  isLoadProspectSuccess: false,
  isLoadProspectFailed: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSendLeadCreationOtpLoading: false,
  isSendLeadCreationOtpSuccess: false,
  isSendLeadCreationOtpFailed: false,
  sendLeadCreationOtpErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReSendLeadCreationOtpLoading: false,
  isReSendLeadCreationOtpSuccess: false,
  isReSendLeadCreationOtpFailed: false,
  reSendLeadCreationOtpErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const leadManagementSlice = createSlice({
  name: 'leadManagement',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(clearLeadManagementState, () => {
        return intial;
      })
      .addCase(getLeadByRM.pending, state => {
        state.isLoadingLeads = true;
        state.isLoadLeadsFailed = false;
        state.isLoadLeadsSuccess = false;
        state.leadsContainer = null;
      })
      .addCase(getLeadByRM.fulfilled, (state, action) => {
        state.isLoadingLeads = false;
        state.isLoadLeadsSuccess = true;
        state.leadsContainer = action.payload;
      })
      .addCase(getLeadByRM.rejected, (state, action) => {
        state.isLoadLeadsSuccess = false;
        state.isLoadingLeads = false;
        state.isLoadLeadsFailed = true;
        state.leadsContainer = null;
        state.errorContainer = {
          ...action.payload
        };
      })
      .addCase(getProspectByRM.pending, state => {
        state.isLoadingProspect = true;
        state.isLoadProspectFailed = false;
        state.isLoadProspectSuccess = false;
        state.leadsContainer = null;
      })
      .addCase(getProspectByRM.fulfilled, (state, action) => {
        state.isLoadingProspect = false;
        state.leadsContainer = action.payload;
      })
      .addCase(getProspectByRM.rejected, (state, action) => {
        state.isLoadProspectSuccess = false;
        state.isLoadingProspect = false;
        state.isLoadProspectFailed = true;
        state.leadsContainer = null;
        state.errorContainer = {
          ...action.payload
        };
      })
      .addCase(sendOtpForLeadCreation.pending, state => {
        state.isSendLeadCreationOtpLoading = true;
        (state.isSendLeadCreationOtpSuccess = false),
          (state.isSendLeadCreationOtpFailed = false),
          (state.sendLeadCreationOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendOtpForLeadCreation.fulfilled, state => {
        (state.isSendLeadCreationOtpLoading = false),
          (state.isSendLeadCreationOtpSuccess = true),
          (state.isSendLeadCreationOtpFailed = false);
      })
      .addCase(sendOtpForLeadCreation.rejected, (state, action) => {
        (state.isSendLeadCreationOtpLoading = false),
          (state.isSendLeadCreationOtpSuccess = false),
          (state.isSendLeadCreationOtpFailed = true),
          (state.sendLeadCreationOtpErrorContainer = {
            ...state.sendLeadCreationOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(reSendOtpForLeadCreation.pending, state => {
        state.isReSendLeadCreationOtpLoading = true;
        (state.isReSendLeadCreationOtpSuccess = false),
          (state.isReSendLeadCreationOtpFailed = false),
          (state.reSendLeadCreationOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(reSendOtpForLeadCreation.fulfilled, state => {
        (state.isReSendLeadCreationOtpLoading = false),
          (state.isReSendLeadCreationOtpSuccess = true),
          (state.isReSendLeadCreationOtpFailed = false);
      })
      .addCase(reSendOtpForLeadCreation.rejected, (state, action) => {
        (state.isReSendLeadCreationOtpLoading = false),
          (state.isReSendLeadCreationOtpSuccess = false),
          (state.isReSendLeadCreationOtpFailed = true),
          (state.reSendLeadCreationOtpErrorContainer = {
            ...state.reSendLeadCreationOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearOtpVerificationState, state => {
        (state.isSendLeadCreationOtpSuccess = false),
          (state.isSendLeadCreationOtpFailed = false),
          (state.isReSendLeadCreationOtpSuccess = false),
          (state.isReSendLeadCreationOtpFailed = false);
      })
      .addCase(getLeadByRMBorrower.pending, state => {
        state.isLoadingLeads = true;
        state.isLoadLeadsFailed = false;
        state.isLoadLeadsSuccess = false;
        state.leadsContainer = null;
      })
      .addCase(getLeadByRMBorrower.fulfilled, (state, action) => {
        state.isLoadingLeads = false;
        state.isLoadLeadsSuccess = true;
        state.leadsContainer = action.payload;
      })
      .addCase(getLeadByRMBorrower.rejected, (state, action) => {
        state.isLoadLeadsSuccess = false;
        state.isLoadingLeads = false;
        state.isLoadLeadsFailed = true;
        state.leadsContainer = null;
        state.errorContainer = {
          ...action.payload
        };
      })
      .addCase(getProspectByRMBorrower.pending, state => {
        state.isLoadingProspect = true;
        state.isLoadProspectFailed = false;
        state.isLoadProspectSuccess = false;
        state.leadsContainer = null;
      })
      .addCase(getProspectByRMBorrower.fulfilled, (state, action) => {
        state.isLoadingProspect = false;
        state.leadsContainer = action.payload;
      })
      .addCase(getProspectByRMBorrower.rejected, (state, action) => {
        state.isLoadProspectSuccess = false;
        state.isLoadingProspect = false;
        state.isLoadProspectFailed = true;
        state.leadsContainer = null;
        state.errorContainer = {
          ...action.payload
        };
      });
  }
});

export default leadManagementSlice.reducer;
