import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuthData, getToken } from '../config/cookie';
import { useDispatch } from 'react-redux';
import { setAuthDetailsByLocalStorage } from '../redux/reducers/authSlice';
import { ROLE_RM } from '../utils/userRoles';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const token = getToken();
  const authData = getAuthData();

  if (token && auth.authData?.userId === 0 && authData !== undefined) {
    dispatch(setAuthDetailsByLocalStorage(authData));
    // dispatch(profilePicView({ userId: authData?.userId })); // removing as not required for RM
  }

  const isAuthenticated = Boolean(token && authData?.role);

  // const isUser = isAuthenticated && authData?.role === ROLE_USER;
  const isRm = isAuthenticated && authData?.role === ROLE_RM;

  const accessibleRoutes = {
    '/homepage': isRm,
    '/add-investor': isRm,
    '/investor-summary': isRm,
    '/add-lead-investor': isRm,
    '/add-lead-otp-investor': isRm,
    '/edit-lead-otp-investor': isRm,
    '/add-lead-borrower': isRm,
    '/add-lead-otp-borrower': isRm,
    '/edit-lead-otp-borrower': isRm,
    '/add-borrower': isRm,
    '/lead-management-investor': isRm,
    '/profile': isRm,
    '/lead-management-borrower': isRm
  };

  if (isAuthenticated) {
    if (accessibleRoutes[window.location.pathname]) {
      return <Outlet />;
    } else {
      return <Navigate to="/forbidden" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
