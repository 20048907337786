import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FormContent, FormButtonBottom } from '../../utils/styledLayout';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  convertToDatePicker,
  createTitle,
  enterOnlyAlphabates,
  enterOnlyNumbers,
  formatDate,
  handleError,
  isValidAadhaarNumber,
  isValidPinCode,
  validateChar
} from '../../utils/utils';
import UploadImage from '../../components/UploadImage';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ModalBox from '../../components/ModalBox';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PropTypes from 'prop-types';
import {
  AADHAR_OCR_BACK,
  AADHAR_OCR_FRONT,
  AADHAR_VERIFICATION,
  SELECT_BACK_BUTTON,
  SELECT_NEXT_BUTTON,
  USER_TYPE_BORROWER
} from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import SnackBarBox from '../../components/SnackBarBox';
import ErrorIcon from '@mui/icons-material/Error';
import ConfirmBox from '../../components/ConfirmBox';
import OtpTimer from '../../components/OtpTimer';
import { colorDanger, colorGolden } from '../../config/theme';
import {
  clearAadhaarVerificationState,
  commonBorrowerDetailsAdd,
  getAddharOtp,
  handleBorrowerBackStep,
  handleBorrowerNextStep,
  validateBorrowerDetails,
  verifyAadhaarByAadhaarOcr,
  verifyAadhaarByOtp
} from '../../redux/reducers/borrowerSlice';

const formControl = {
  margin: '0.6rem auto'
};

function AadhaarVerificationBorrower({ borrowerId }) {
  const dispatch = useDispatch();
  const [otpExpired, setOTPExpired] = useState(false);

  const oneMinuteFromNow = 60;

  const handleOTPTimerExpired = () => {
    setOTPExpired(true);
  };

  const [otpVerifier, setOtpVerifier] = useState({
    otp: '',
    isOtpError: false,
    errorMessage: ''
  });

  const [aadhaarVerifier, setAadhaarVerifier] = useState({
    aadhaarNumber: '',
    isAadhaarError: false,
    errorMessage: ''
  });

  const [profileType, setProfileType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    isAadhaarVerificationByOtpLoading,
    isAadhaarVerificationByOtpSuccess,
    isAadhaarVerificationByOtpFailed,
    isAadhaarVerificationByOcrLoading,
    isAadhaarVerificationByOcrSuccess,
    isAadhaarVerificationByOcrFailed,
    isGetAadhaarOtpLoading,
    isGetAadhaarOtpSuccess,
    isGetAadhaarOtpFailed,
    aadharVerificationByOcrErrorContainer,
    aadhaarVerificationByOtpErrorContainer,
    getAddharOtpErrorContainer,
    borrowerDetails,
    aadharOtpDetails
  } = useSelector(state => state.borrowers);

  const isAadhaarDetailsAvailable = Boolean(
    borrowerDetails?.aadhaarNumber ||
      borrowerDetails?.nameAsPerAadhaar ||
      borrowerDetails?.dobAsPerAadhaar ||
      borrowerDetails?.address1 ||
      borrowerDetails?.landmark ||
      borrowerDetails?.pinCode ||
      borrowerDetails?.city ||
      borrowerDetails?.state
  );

  useEffect(() => {
    if (!isAadhaarVerificationByOtpLoading || !isGetAadhaarOtpLoading) {
      if (profileType === 'aadhaarOtp') setProfileType('');
    }
  }, [isAadhaarVerificationByOtpFailed, isGetAadhaarOtpFailed]);

  useEffect(() => {
    document.title = createTitle('Aadhaar Verification');
    return () => dispatch(clearAadhaarVerificationState());
  }, []);

  const handleSubmitWithValidation = async values => {
    try {
      let borrower = await validateBorrowerDetails(borrowerId);
      const {
        aadhaarNumber,
        nameAsPerAadhaar,
        dobAsPerAadhaar,
        address1,
        landmark,
        pinCode,
        state,
        city
      } = borrower.borrowerDetails;
      if (
        aadhaarNumber &&
        nameAsPerAadhaar &&
        dobAsPerAadhaar &&
        address1 &&
        landmark &&
        pinCode &&
        state &&
        city
      ) {
        dispatch(handleBorrowerNextStep());
      } else {
        const newvalues = {
          borrowerDetails: {
            ...values,
            dobAsPerAadhaar: formatDate(values.dobAsPerAadhaar, 'YYYY-MM-DD')
          },
          action: AADHAR_VERIFICATION
        };
        dispatch(commonBorrowerDetailsAdd(newvalues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    formik.setValues({
      id: borrowerDetails?.id || 0,
      aadhaarNumber: borrowerDetails?.aadhaarNumber
        ? borrowerDetails?.aadhaarNumber
        : aadhaarVerifier.aadhaarNumber || '',
      nameAsPerAadhaar: borrowerDetails?.nameAsPerAadhaar || '',
      dobAsPerAadhaar: borrowerDetails?.dobAsPerAadhaar
        ? dayjs(convertToDatePicker(borrowerDetails?.dobAsPerAadhaar))
        : null,
      address1: borrowerDetails?.address1 || '',
      address2:
        borrowerDetails?.address2 && borrowerDetails?.address2 !== 'null'
          ? borrowerDetails?.address2
          : '',
      landmark:
        borrowerDetails?.landmark && borrowerDetails?.landmark !== 'null'
          ? borrowerDetails?.landmark
          : '',
      pinCode: borrowerDetails?.pinCode || '',
      city: borrowerDetails?.city || '',
      state: borrowerDetails?.state || '',
      userId: borrowerId || 0
    });
  }, [borrowerDetails]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      aadhaarNumber: '',
      nameAsPerAadhaar: '',
      dobAsPerAadhaar: null,
      address1: '',
      address2: '',
      landmark: '',
      pinCode: '',
      city: '',
      state: '',
      userId: 0
    },
    validationSchema: yup.object({
      profileType: yup.string(),
      aadhaarNumber: yup
        .string()
        .test(
          'validate-aadhaar',
          'Please enter a valid Aadhaar number.',
          value => isValidAadhaarNumber(value)
        )
        .required('Please provide your Aadhaar number.'),
      nameAsPerAadhaar: yup
        .string()
        .required('Please provide your name as per Aadhaar.'),
      address1: yup.string().required('Please provide Address Line 1.'),
      address2: yup.string().required('Please provide Address Line 2.'),
      landmark: yup.string().required('Please provide landmark.'),
      pinCode: yup
        .string()
        .test(
          'is-address',
          'Please enter a valid Pincode without using special symbols.',
          value => isValidPinCode(value)
        )
        .required('Please provide Pincode.'),
      city: yup.string().required('Please provide City.'),
      state: yup.string().required('Please provide State.'),
      dobAsPerAadhaar: yup.date().required('Please provide your Date of Birth.')
    }),
    onSubmit: values => {
      handleSubmitWithValidation(values);
    }
  });

  useEffect(() => {
    const isAadhaarValid = isValidAadhaarNumber(aadhaarVerifier.aadhaarNumber);
    if (
      profileType === 'aadhaarOtp' &&
      isAadhaarValid &&
      aadhaarVerifier.aadhaarNumber.length === 12
    ) {
      setOtpVerifier({
        otp: '',
        isOtpError: false,
        errorMessage: ''
      });
      setIsModalOpen(true);
    } else if (aadhaarVerifier.aadhaarNumber.length === 12 && isAadhaarValid) {
      handleAadhaarVerification(aadhaarVerifier.aadhaarNumber);
    }
  }, [profileType, aadhaarVerifier.aadhaarNumber]);

  const handleClose = () => {
    setIsModalOpen(false);
    setProfileType('');
  };
  const handleSubmit = () => {
    setIsModalOpen(false);
    sendAadhaarOtp();
  };

  const sendAadhaarOtp = () => {
    setOtpVerifier({
      otp: '',
      isOtpError: false,
      errorMessage: ''
    });
    dispatch(
      getAddharOtp({
        aadhaarNo: aadhaarVerifier.aadhaarNumber
      })
    );
  };

  const onImageUpload = fileData => {
    if (Object.keys(fileData).length !== 0) {
      const formData = new FormData();
      formData.append('userId', borrowerId);
      formData.append('userType', USER_TYPE_BORROWER);
      formData.append('file', fileData?.file);
      formData.append('ocrType', fileData?.docName);
      dispatch(verifyAadhaarByAadhaarOcr(formData));
    }
  };

  const handleResendOtp = () => {
    setOtpVerifier(prev => {
      return { ...prev, otp: '' };
    });

    const isAadhaarValid = isValidAadhaarNumber(aadhaarVerifier.aadhaarNumber);
    if (isAadhaarValid && aadhaarVerifier.aadhaarNumber.length === 12) {
      sendAadhaarOtp();
    } else {
      handleAadhaarVerification(aadhaarVerifier.aadhaarNumber);
    }
  };

  const handleVerifyOtp = () => {
    const validOtp = isValidPinCode(otpVerifier.otp);
    if (validOtp) {
      setOtpVerifier({
        ...otpVerifier,
        isOtpError: false,
        errorMessage: ''
      });
      dispatch(
        verifyAadhaarByOtp({
          aadhaarNo: aadhaarVerifier.aadhaarNumber,
          otp: otpVerifier.otp,
          requestId: aadharOtpDetails?.requestId
        })
      );
    } else {
      setOtpVerifier({
        ...otpVerifier,
        isOtpError: true,
        errorMessage: 'OTP is required.'
      });
    }
  };
  const handleOtpChange = e => {
    setOtpVerifier({
      ...otpVerifier,
      otp: e.trim(),
      isOtpError: false,
      errorMessage: ''
    });
  };

  const isValidAadhaar = Boolean(
    !(
      aadhaarVerifier.aadhaarNumber.trim() !== '' &&
      aadhaarVerifier.aadhaarNumber.length === 12
    )
  );

  const handleAadhaarVerification = val => {
    const isNumberOnly = /^\d+$/.test(val);

    if (isNumberOnly && val.length === 12) {
      setAadhaarVerifier({
        ...aadhaarVerifier,
        isAadhaarError: false,
        errorMessage: ''
      });
    } else {
      setAadhaarVerifier({
        ...aadhaarVerifier,
        isAadhaarError: true,
        errorMessage: 'Please input a valid Aadhaar number.'
      });
    }
  };

  const handleAadhaarChange = e => {
    setAadhaarVerifier({
      ...aadhaarVerifier,
      aadhaarNumber: enterOnlyNumbers(e.target.value.trim())
    });
  };

  const handleProfileChange = event => {
    setProfileType(event.target.value);
  };

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <FormContent>
        <Typography
          marginBottom={'1rem'}
          align="center"
          variant="body1"
          fontWeight={700}
        >
          Aadhaar Verification
        </Typography>

        {!(
          isAadhaarVerificationByOtpSuccess ||
          isAadhaarVerificationByOcrSuccess ||
          isAadhaarDetailsAvailable
        ) && (
          <>
            <FormControl fullWidth size="small">
              <TextField
                type="tel"
                size="small"
                label="Aadhaar Number"
                required
                inputProps={{ maxLength: 12 }}
                value={aadhaarVerifier.aadhaarNumber}
                onChange={e => handleAadhaarChange(e)}
                error={Boolean(aadhaarVerifier.isAadhaarError)}
                helperText={aadhaarVerifier.errorMessage}
              />
            </FormControl>
            <Box display="flex" alignItems="center" marginBottom="1rem">
              <ErrorIcon sx={{ fontSize: 'large', color: colorGolden }} />
              <Typography
                marginLeft="5px"
                variant="body2"
                align="left"
                fontSize={13}
              >
                Please enter your Aadhaar number for authentication.
              </Typography>
            </Box>

            <FormControl sx={{ marginTop: '1.5rem' }} fullWidth size="small">
              <Typography
                align="left"
                variant="body2"
                fontWeight={500}
                color="black"
              >
                Do you want to process with Aadhaar authentication?
                <span style={{ color: colorDanger }}>*</span>
              </Typography>
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <RadioGroup
                name="profileType"
                value={profileType}
                onChange={handleProfileChange}
              >
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Tooltip
                      placement="top"
                      title={
                        !isValidAadhaar
                          ? 'Choose Aadhaar OTP, you will receive an OTP.'
                          : 'Enter a Valid Aadhaar number first'
                      }
                    >
                      <FormControlLabel
                        value="aadhaarOtp"
                        control={
                          <Radio disabled={isValidAadhaar} color="golden" />
                        }
                        label="Aadhaar OTP"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip
                      placement="top"
                      title={
                        !isValidAadhaar
                          ? 'Choose Aadhaar Card Image, and details will be fetched automatically.'
                          : 'Enter a Valid Aadhaar number first'
                      }
                    >
                      <FormControlLabel
                        value="aadhaarOcr"
                        control={
                          <Radio disabled={isValidAadhaar} color="golden" />
                        }
                        label="Aadhaar Image"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </>
        )}

        {profileType && profileType === 'aadhaarOcr' && (
          <>
            <FormControl sx={formControl} fullWidth size="small">
              <Typography
                align="left"
                variant="body2"
                fontWeight={500}
                color="black"
              >
                Upload Aadhaar Card<span style={{ color: colorDanger }}>*</span>
              </Typography>
            </FormControl>

            <Grid
              spacing={2}
              container
              direction="row"
              justifyContent="space-between"
              marginTop={2}
              marginBottom={4}
            >
              <Grid item xs={6}>
                <Typography marginBottom={2} variant="subtitle2">
                  Front Side
                </Typography>
                <UploadImage
                  onImageUpload={onImageUpload}
                  docName={AADHAR_OCR_FRONT}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography marginBottom={2} variant="subtitle2">
                  Back Side
                </Typography>
                <UploadImage
                  onImageUpload={onImageUpload}
                  docName={AADHAR_OCR_BACK}
                />
              </Grid>
            </Grid>
          </>
        )}

        {(isAadhaarVerificationByOcrSuccess ||
          isAadhaarVerificationByOtpSuccess ||
          isAadhaarDetailsAvailable) && (
          <>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                label="Aadhaar Number"
                required
                inputProps={{ maxLength: 12 }}
                name="aadhaarNumber"
                value={formik.values.aadhaarNumber}
                onChange={e =>
                  formik.setFieldValue(
                    'aadhaarNumber',
                    enterOnlyNumbers(e.target.value)
                  )
                }
                disabled={Boolean(
                  borrowerDetails?.aadhaarNumber ||
                    aadhaarVerifier.aadhaarNumber
                )}
                error={Boolean(
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                )}
                helperText={
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                }
              />
            </FormControl>
            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="Name as per aadhaar"
                name="nameAsPerAadhaar"
                required
                inputProps={{ maxLength: 60 }}
                value={formik.values.nameAsPerAadhaar}
                size="small"
                onChange={e =>
                  formik.setFieldValue(
                    'nameAsPerAadhaar',
                    enterOnlyAlphabates(e.target.value)
                  )
                }
                disabled={Boolean(borrowerDetails?.nameAsPerAadhaar)}
                error={Boolean(
                  formik.touched.nameAsPerAadhaar &&
                    formik.errors.nameAsPerAadhaar
                )}
                helperText={
                  formik.touched.nameAsPerAadhaar &&
                  formik.errors.nameAsPerAadhaar
                }
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  disableFuture
                  disabled={Boolean(borrowerDetails?.dobAsPerAadhaar)}
                  slotProps={{ textField: { size: 'small' } }}
                  format="DD/MM/YYYY"
                  onChange={value =>
                    formik.setFieldValue('dobAsPerAadhaar', value, true)
                  }
                  value={formik.values.dobAsPerAadhaar}
                  textField={props => (
                    <TextField
                      {...props}
                      label="Date of Birth"
                      margin="normal"
                      name="dobAsPerAadhaar"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.dobAsPerAadhaar &&
                formik.errors.dobAsPerAadhaar && (
                  <FormHelperText sx={{ textAlign: 'start' }}>
                    {formik.errors.dobAsPerAadhaar}
                  </FormHelperText>
                )}
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="Address Line 1"
                name="address1"
                multiline
                rows="2"
                required
                inputProps={{ maxLength: 100 }}
                value={formik.values.address1}
                size="small"
                disabled={Boolean(borrowerDetails?.address1)}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.address1 && formik.errors.address1
                )}
                helperText={formik.touched.address1 && formik.errors.address1}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="Address Line 2"
                name="address2"
                required
                inputProps={{ maxLength: 100 }}
                value={formik.values.address2}
                size="small"
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.address2 && formik.errors.address2
                )}
                helperText={formik.touched.address2 && formik.errors.address2}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="Landmark"
                name="landmark"
                required
                inputProps={{ maxLength: 100 }}
                value={formik.values.landmark}
                size="small"
                onChange={e =>
                  formik.setFieldValue(
                    'landmark',
                    enterOnlyAlphabates(e.target.value)
                  )
                }
                error={Boolean(
                  formik.touched.landmark && formik.errors.landmark
                )}
                helperText={formik.touched.landmark && formik.errors.landmark}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                type="tel"
                label="Pincode"
                name="pinCode"
                required
                inputProps={{ maxLength: 6, minLength: 6 }}
                value={formik.values.pinCode}
                size="small"
                onChange={e =>
                  formik.setFieldValue(
                    'pinCode',
                    enterOnlyNumbers(e.target.value)
                  )
                }
                error={Boolean(formik.touched.pinCode && formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="City"
                name="city"
                required
                inputProps={{ maxLength: 60 }}
                value={formik.values.city}
                size="small"
                onChange={e =>
                  formik.setFieldValue(
                    'city',
                    enterOnlyAlphabates(e.target.value)
                  )
                }
                error={Boolean(formik.touched.city && formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </FormControl>

            <FormControl sx={formControl} fullWidth size="small">
              <TextField
                label="State"
                name="state"
                required
                inputProps={{ maxLength: 60 }}
                value={formik.values.state}
                size="small"
                onChange={e =>
                  formik.setFieldValue(
                    'state',
                    enterOnlyAlphabates(e.target.value)
                  )
                }
                error={Boolean(formik.touched.state && formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </FormControl>
          </>
        )}

        {!isGetAadhaarOtpLoading &&
          isGetAadhaarOtpSuccess &&
          !isAadhaarVerificationByOtpSuccess && (
            <ModalBox title="OTP Verification">
              <Typography align="center" variant="subtitle2" marginTop={2}>
                Enter OTP sent to the number via SMS
              </Typography>

              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                sx={{ marginTop: '1.5rem' }}
              >
                <MuiOtpInput
                  className="otp_input_custom_long"
                  length={6}
                  value={otpVerifier.otp}
                  onChange={handleOtpChange}
                  validateChar={validateChar}
                  TextFieldsProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    type: 'tel'
                  }}
                />
                {otpVerifier.isOtpError && (
                  <FormHelperText sx={{ textAlign: 'center' }}>
                    {otpVerifier.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>

              {!otpExpired && (
                <OtpTimer
                  expiryTimestamp={oneMinuteFromNow}
                  onTimeout={handleOTPTimerExpired}
                />
              )}

              <Typography
                sx={formControl}
                align="center"
                variant="subtitle2"
                fontSize={'0.8rem'}
              >
                Didn&apos;t receive a OTP?
              </Typography>

              <Typography sx={formControl} align="center" variant="body2">
                <Button
                  size="small"
                  variant="default"
                  onClick={handleResendOtp}
                  disabled={!otpExpired}
                >
                  RESEND OTP
                </Button>
              </Typography>

              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={2}
                disabled={isAadhaarVerificationByOtpLoading}
              >
                <Grid item xs={3}>
                  <LoadingButton
                    style={{ textTransform: 'uppercase' }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleVerifyOtp}
                    disabled={isAadhaarVerificationByOtpLoading}
                  >
                    {isAadhaarVerificationByOtpLoading
                      ? 'Verifying'
                      : 'Verify & Proceed'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </ModalBox>
          )}
      </FormContent>
      <Box>
        <FormButtonBottom>
          {formik.touched.aadhaarNumber && formik.errors.aadhaarNumber && (
            <FormHelperText sx={{ textAlign: 'start' }}>
              Please complete the Aadhaar authentication process first and then
              click the Save & Next button.
            </FormHelperText>
          )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <Tooltip title={SELECT_BACK_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => dispatch(handleBorrowerBackStep())}
                >
                  Back
                </LoadingButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip placement="top" title={SELECT_NEXT_BUTTON}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save & next
                </LoadingButton>
              </Tooltip>
            </Grid>
          </Grid>
        </FormButtonBottom>
      </Box>

      {isModalOpen && (
        <ConfirmBox
          title="Confirm Aadhaar Number"
          subtitle={
            <>
              <Typography variant="subtitle2" fontWeight={600}>
                Please confirm whether the entered Aadhaar number is correct.
              </Typography>
              <Typography marginTop={1} variant="subtitle2">
                <strong>Aadhaar Number : </strong>{' '}
                {aadhaarVerifier.aadhaarNumber}
              </Typography>
            </>
          }
          isOpen={isModalOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}

      {isGetAadhaarOtpLoading && <Loader text="Sending OTP" />}
      {isAadhaarVerificationByOtpLoading && (
        <Loader text="Verifying details by OTP" />
      )}
      {isAadhaarVerificationByOcrLoading && (
        <Loader text="Verifying details by Image" />
      )}

      {!isAadhaarVerificationByOtpLoading &&
        isAadhaarVerificationByOtpFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              aadhaarVerificationByOtpErrorContainer?.errorMessage
            )}
          />
        )}
      {!isGetAadhaarOtpLoading && isGetAadhaarOtpFailed && (
        <SnackBarBox
          type="error"
          message={handleError(getAddharOtpErrorContainer?.errorMessage)}
        />
      )}
      {!isAadhaarVerificationByOcrLoading &&
        isAadhaarVerificationByOcrFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              aadharVerificationByOcrErrorContainer?.errorMessage
            )}
          />
        )}
      {!isAadhaarVerificationByOcrLoading &&
        isAadhaarVerificationByOcrSuccess && (
          <SnackBarBox
            type="success"
            message="Aadhaar verification via Image successful."
          />
        )}
      {!isAadhaarVerificationByOtpLoading &&
        isAadhaarVerificationByOtpSuccess && (
          <SnackBarBox
            type="success"
            message="Aadhaar verification via OTP successful."
          />
        )}
      {!isGetAadhaarOtpLoading && isGetAadhaarOtpSuccess && (
        <SnackBarBox
          type="success"
          message={
            aadharOtpDetails?.result?.message ||
            'OTP sent to registered mobile number'
          }
        />
      )}
    </form>
  );
}
AadhaarVerificationBorrower.propTypes = {
  borrowerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default AadhaarVerificationBorrower;
