import { Box, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, handleError } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import CenteredLogo from '../components/CenteredLogo';
import { FormContent, FormLayout } from '../utils/styledLayout';
import SnackBarBox from '../components/SnackBarBox';
import { clearLoginState, pingServer } from '../redux/reducers/authSlice';
import { ROLE_RM } from '../utils/userRoles';
import UserLogin from '../containers/auth/Login';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pingServer());
    document.title = createTitle('Login');
    return () => dispatch(clearLoginState());
  }, []);

  const {
    isLoading,
    isLoggedIn,
    isError,
    errorContainer,
    authData,
    applicationStatus,
    isPingServerLoading
  } = useSelector(state => state.auth);

  useEffect(() => {
    if (isLoggedIn && authData?.role === ROLE_RM) {
      navigate('/homepage');
      return;
    }
  }, [isLoggedIn]);

  return (
    <FormLayout>
      <Container>
        <Box marginTop="1rem">
          <FormContent>
            <CenteredLogo />
            {!isPingServerLoading && applicationStatus === 'DOWN' && (
              <Typography variant="subtitle2" align="left">
                🔴 System is offline. Please try again later.
              </Typography>
            )}
            <UserLogin />
          </FormContent>
        </Box>

        {isPingServerLoading && (
          <Loader key="ping" text="Validating the backend service." />
        )}
        {!isLoading && isError && (
          <SnackBarBox
            type="error"
            message={handleError(errorContainer?.errorMessage)}
          />
        )}
        {isLoading && <Loader text="Sending Otp..." />}
      </Container>
    </FormLayout>
  );
}
