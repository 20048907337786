import { useEffect, useState } from 'react';
import { createTitle, handleError } from '../utils/utils';
import { FormContent } from '../utils/styledLayout';
import {
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography,
  Hidden,
  Card,
  CardContent
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  dashboardIncentiveGraph,
  setLayoutTitle
} from '../redux/reducers/layoutSlice';
import { IMG_LENDBOX, IMG_RBI } from '../utils/imageUrls';
import { setBackButtonActionFalse } from '../redux/reducers/investorSlice';
import { colorDarkGray, colorGolden, colorGrayish } from '../config/theme';
import DashboardIncentiveGraph from '../containers/dashboard/DashboardIncentiveGraph';
import SnackBarBox from '../components/SnackBarBox';
import CircleIcon from '@mui/icons-material/Circle';

const cardContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '0',
  backgroundColor: 'red!important'
};

const cardStyles = {
  textAlign: 'center',
  width: '100%',
  height: '0',
  paddingBottom: '100%',
  position: 'relative',
  backgroundColor: '#FEC310',
  borderRadius: '24px',
  boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19)'
};

const imageStyles = {
  width: '60%',
  height: '60%',
  borderRadius: '50%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '8px'
};

const textStyles = {
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  fontWeight: 'bold'
};

const typographyContainerStyles = {
  display: 'flex',
  height: '100%',
  padding: '0'
};

const cardStyleGrey = {
  backgroundColor: '#F5F5F5',
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '10px'
};

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authData } = useSelector(state => state.auth);
  const {
    isDashboardIncentiveGraphData,
    DashboardIncentiveGraphErrorContainer
  } = useSelector(state => state.layout);
  const [isLoadingDash, setIsLoadingDash] = useState(true);

  useEffect(() => {
    dispatch(setBackButtonActionFalse());
    document.title = createTitle('Dashboard');
    dispatch(
      setLayoutTitle({
        title: 'Dashboard'
      })
    );
    dispatch(dashboardIncentiveGraph({ userId: authData.userId }));
    setIsLoadingDash(false);
  }, []);

  const handleFlowControl = user => {
    if (user.toLowerCase() === 'investor') {
      navigate('/lead-management-investor');
    } else if (user.toLowerCase() === 'borrower') {
      navigate('/lead-management-borrower');
    }
  };
  return (
    <>
      {isLoadingDash ? (
        <Loader text="We welcome you to our homepage." />
      ) : (
        <>
          <Container className="container_css">
            <FormContent>
              <div style={typographyContainerStyles}>
                <Typography style={{ fontSize: 24 }} variant="subtitle1">
                  Welcome,
                  <span style={{ marginLeft: 5, fontWeight: 'bold' }}>
                    {authData.fullName || ''}
                  </span>
                </Typography>
              </div>
              <Grid container spacing={3} paddingTop={3}>
                <Grid item xs={6} md={6} lg={6}>
                  <Tooltip
                    placement="top"
                    title="Begin your journey as a investor."
                  >
                    <div style={cardContainerStyles}>
                      <Paper
                        onClick={() => handleFlowControl('investor')}
                        className="dashboard_card"
                        elevation={3}
                        style={cardStyles}
                      >
                        <img
                          src="/images/investor_icon.svg"
                          alt="Card 2"
                          style={imageStyles}
                        />
                        <Typography style={textStyles} variant="subtitle1">
                          Investor
                        </Typography>
                      </Paper>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Tooltip
                    placement="top"
                    title="Begin your journey as a borrower."
                  >
                    <div style={{ ...cardContainerStyles }}>
                      <Paper
                        onClick={() => handleFlowControl('borrower')}
                        elevation={3}
                        className="dashboard_card"
                        style={cardStyles}
                      >
                        <img
                          src="/images/borrower_icon.svg"
                          alt="Card 1"
                          style={{ ...imageStyles }}
                        />
                        <Typography style={textStyles} variant="subtitle1">
                          Borrower
                        </Typography>
                      </Paper>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>

              <Card
                elevation={0}
                sx={{ ...cardStyleGrey, marginTop: '3rem' }}
                className="dashboard_card"
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                      >
                        Monthly
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Grid item xs sx={{ display: 'inline-flex' }}>
                      <CircleIcon sx={{ color: colorGolden }} />
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                        color={colorGrayish}
                        marginLeft={1}
                      >
                        Target
                      </Typography>
                    </Grid>

                    <Grid item xs sx={{ display: 'inline-flex' }}>
                      <CircleIcon sx={{ color: colorDarkGray }} />
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                        color={colorGrayish}
                        marginLeft={1}
                      >
                        Achieved
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="subtitle2"
                        align="left"
                        fontWeight={600}
                        marginLeft={4}
                      >
                        ₹{' '}
                        {new Intl.NumberFormat('en-IN').format(
                          isDashboardIncentiveGraphData?.targetAmount
                            ? isDashboardIncentiveGraphData?.targetAmount
                            : 0
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="subtitle2"
                        align="left"
                        fontWeight={600}
                        marginLeft={4}
                      >
                        ₹{' '}
                        {new Intl.NumberFormat('en-IN').format(
                          isDashboardIncentiveGraphData?.achievedAmount
                            ? isDashboardIncentiveGraphData?.achievedAmount
                            : 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {Number(isDashboardIncentiveGraphData?.targetAmount) > 0 && (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={2}
                    marginBottom={2}
                  >
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                      >
                        Weekly
                      </Typography>
                    </Grid>
                  </Grid>

                  <DashboardIncentiveGraph
                    amount={isDashboardIncentiveGraphData?.targetAmount}
                    target={
                      isDashboardIncentiveGraphData?.weeklyTargetAmount || []
                    }
                    achieved={
                      isDashboardIncentiveGraphData?.weeklyAchievedAmount || []
                    }
                  />
                </>
              )}
            </FormContent>
          </Container>

          <Hidden mdDown>
            <Paper
              sx={{
                zIndex: '0 important',
                position: 'relative',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: 'none',
                backgroundColor: 'white',
                margin: '3rem 1rem 0rem 1rem'
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                  <img
                    src={IMG_LENDBOX}
                    alt="Powered by Lendbox"
                    height={50}
                    width={170}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <img
                    src={IMG_RBI}
                    alt="Regulated by RBI"
                    height={50}
                    width={50}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Hidden>

          <Hidden mdUp>
            <Paper
              sx={{
                marginTop: '2rem',
                zIndex: '0 important',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: 'none',
                backgroundColor: 'white'
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'left', paddingLeft: '1rem' }}
                >
                  <img
                    src={IMG_LENDBOX}
                    alt="Powered by Lendbox"
                    height={50}
                    width={170}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'right', paddingRight: '1rem' }}
                >
                  <img
                    src={IMG_RBI}
                    alt="Regulated by RBI"
                    height={50}
                    width={50}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Hidden>
        </>
      )}

      {!isLoadingDash && DashboardIncentiveGraphErrorContainer?.error && (
        <SnackBarBox
          type="error"
          message={handleError(
            DashboardIncentiveGraphErrorContainer?.errorMessage
          )}
        />
      )}
    </>
  );
}

export default Dashboard;
